import React from "react";
import Header from "components/header";
import { graphql } from "gatsby";
import Seo from "components/seo";
import Layout from "components/layout";

const HowItWorksDetails = ({ data }) => {
  const item = data.howItWorksJson;

  return (
    <React.Fragment>
      <Layout>
        <Seo title={item.seo.title} description={item.seo.description} />
        <Header />
        <div className="mt-32 sir-container mb-20 sm:mb-32">
          <div className="text-left space-y-5">
            <h1 className="text-3xl text-primary font-bold">{item.name}</h1>
            <div className="text-left flex justify-left full-screen">
              <iframe
                src={`https://player.vimeo.com/video/${item.video}`}
                frameBorder="0"
                allow="autoplay; encrypted-media"
                title="video"
              ></iframe>
            </div>
            <p>
              <div dangerouslySetInnerHTML={{ __html: item.description }} />
            </p>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default HowItWorksDetails;

export const query = graphql`
  query($slug: String!) {
    howItWorksJson(slug: { eq: $slug }) {
      name
      description
      slug
      video
      seo {
        title
        description
      }
      image {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 50)
        }
      }
    }
  }
`;
